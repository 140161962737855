import './Slippage.css'
import { useSelector, useDispatch} from 'react-redux';
import { slippageActions } from '../Store/slippage-slice';


const Slippage = () => {
    const dispatch = useDispatch()
    const slippageState = useSelector((state: any) => state.slippage.slippageState);
    let style = '';
    if (slippageState){
        style = 'slippage slippage-open'
       
    }
    else {
        style = 'slippage slippage-close'
    }
        


    const slippageBtnHandler = () => {
        if(slippageState){
            dispatch(slippageActions.close())
        }
        else{
            dispatch(slippageActions.open())
        }

    }

    return(<div className={style}>
        <div className='slippage-header'>
            <div className='slippage-header-title'>Slippage Tolerance</div>
            <div className='slippage-xBtn' onClick={slippageBtnHandler}></div>
        </div>
        <div className='slippage-content'>
            <div className='slippage-content-first-row'>
                <input type='text' placeholder='13%'  className='slippage-content-input'></input>
                <input type='text' placeholder='13%'  className='slippage-content-input'></input>
                <input type='text' placeholder='13%'  className='slippage-content-input'></input>
                <input type='text' placeholder='13%'  className='slippage-content-input'></input>
            </div>
            <div className='slippage-content-second-row'>
                <input type='text'  placeholder='13                                   %' className='slippage-content-input-long'></input>
                <div className='slippage-content-btn'>Auto</div>
            </div>
        </div>
    </div>)
}
export default Slippage;