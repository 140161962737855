import './Pools.css'

import PoolItem from './PoolItem';
import SelectWallet from './SelectWallet';

import { currency1, currency2, currency3, currency4, currency5, currency6 } from '../Assets/assetsdb';
import poolsLayer from '../Assets/pools_layer.svg'
const Pools = () => {
    return(<div className='pools'>
        <SelectWallet></SelectWallet>
        <div className='img-pools-container'>
                 <img className='img-pools-layer' src={poolsLayer} alt='pools layer'></img>
            </div>
        <div className='pools-subtotal-container'>
            
            <div className='currency-subtotal'>
                <div className='currency-subtotal-title'>Total Value Loked</div>
                <div className='currency-subtotal-value'>$11,643,902</div>
            </div>
            <div className='currency-subtotal'>
                <div className='currency-subtotal-title'>Total Value Loked</div>
                <div className='currency-subtotal-value'>$700,532</div>
            </div>
            <div className='currency-subtotal'>
                <div className='currency-subtotal-title'>APR</div>
                <div className='currency-subtotal-value'>-</div>
            </div>
        </div>
        <div className='pools-container'>
            <PoolItem currencyImg1={currency1} currencyImg2={currency2} currencyTitle1='TRA' currencyTitle2='BTC' currencyValue='2,341,162' currencyValue24='129,451'></PoolItem>
            <PoolItem currencyImg1={currency3} currencyImg2={currency4} currencyTitle1='UNI' currencyTitle2='C98' currencyValue='2,341,162' currencyValue24='129,451'></PoolItem>
            <PoolItem currencyImg1={currency5} currencyImg2={currency6} currencyTitle1='USDT' currencyTitle2='TRB' currencyValue='2,341,162' currencyValue24='129,451'></PoolItem>
        </div>
       </div>)
}

export default Pools;