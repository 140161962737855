import Button from '../UI/Button'
import Slippage from './Slippage'
import './Swap.css'
import swapLayer from '../Assets/swap_layer.svg'

import cur2 from '../Assets/curLogo2.svg'
import usdt from '../Assets/USDT.svg'
import { currency1 } from '../Assets/assetsdb'
import { slippageActions } from '../Store/slippage-slice'

import { useSelector, useDispatch } from 'react-redux'
const Swap = () => {
    const dispatch = useDispatch()
    const slippageState = useSelector((state: any) => state.slippage.slippageState);
    let style = '';
    if(slippageState){
        style='backdrop-open'
    }
    else{
        style='backdrop-hidden'
    }
    const slippageBtnHandler = () => {
        if(slippageState){
            dispatch(slippageActions.close())
        }
        else{
            dispatch(slippageActions.open())
            
        }
    }
    const backdropHandler = () => {
        if(slippageState){
            dispatch(slippageActions.close())
        }
        else{
            dispatch(slippageActions.open())
            
        }
    }
    return(<div>
        <div className={style} onClick={backdropHandler}></div>
        <Slippage></Slippage>
        <div className='img-wrapper'>
            <div className='img-container'>
                 <img className='img-layer' src={swapLayer} alt=''></img>
            </div>
        <div className='swap-window'>
            
            <div className='cirkle-btn-wrapper'>
                <div className='cirkle-btn'></div>
            </div>
            <div className='swap-window-header'>
                <div className='rotateBtn'></div>
                <div className='swap-window-title'>Swap</div>
                <div className='settingBtn' onClick={slippageBtnHandler}></div>
            </div>
            <div className='first-currency currency-background'>
                <div className='currency-container'>
                    <div className='currency-wrapper'>
                        <div className='curLogo'></div>
                        <div className='curTitle'>TRA</div>
                        <div className='curBtn'></div>
                    </div>
                    <div className='number-style'>1</div>
                </div>
                <div className='currency-container'>
                    <div className='currency-value'>Balance: <span>592,329</span></div>
                    <div className='currency-value'><span>$ 15.4072</span></div>
                </div>
               
            </div>
            <div className='second-currency currency-background'>
                <div className='currency-container'>
                    <div className='currency-wrapper'>
                        <div className='curLogo2'></div>
                        <div className='curTitle'>BTC</div>
                        <div className='curBtn'></div>
                    </div>
                    <div className='number-style'>0,00010523025</div>
                </div>
                <div className='currency-container'>
                    <div className='currency-value'>Balance: <span>0</span></div>
                    <div className='currency-value'><span>$ 15.4072</span></div>
                </div>
                <div className='currency-route'>
                    <div className='route-text'>Route:</div>
                    <div className='route'>
                        <div className='rout-currency'>
                            <img src={currency1}className='rout-currency-img1' alt=''></img>
                            <div className='rout-title'>TRA</div>
                        </div>
                        <div className='arrow'></div>
                       <div className='rout-currency'>
                            <img src={usdt}className='rout-currency-img1' alt=''></img>
                            <div className='rout-title'>TRA</div>
                        </div>
                        <div className='arrow'></div>
                        <div className='rout-currency'>
                            <img src={cur2}className='rout-currency-img1' alt=''></img>
                            <div className='rout-title'>TRA</div>
                        </div>
                    </div>
                </div>
                <div className='currency-impact'>
                    <div className='currency-impact-left'>
                        <div className='currency-impact-title'>1 BTC = 15662 TRA <span>($15.4072)</span></div>
                        <div className='currency-impact-title'>Slippage: <span>1%</span></div>
                    </div>
                    <div className='impact'>Impact: -0.76%</div>
                </div>
               
            </div>
            <Button name='proceed to checkout' to='/'></Button>

        </div>
        </div>
    </div>)
}

export default Swap;