import assert from "./arrow.svg";
import cur1 from './curLogo.svg'
import cur2 from './curLogo2.svg'
import cur3 from './curLogo3.svg'
import cur4 from './curLogo4.svg'
import cur5 from './curLogo5.svg'
import cur6 from './curLogo6.svg'

export const asset1 = assert;
export const currency1 = cur1;
export const currency2 = cur2;
export const currency3 = cur3;
export const currency4 = cur4;
export const currency5 = cur5;
export const currency6 = cur6;