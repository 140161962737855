import './PoolItem.css'

import Button from '../UI/Button';


const PoolItem = (props: any) => {
    return(<div className='pool-container'>
        <div className='responsive-wrapper'>
            <div className='currency-icons-container'>
                <img src={props.currencyImg1} className='currency-icon' alt='currency1'></img>
                <img src={props.currencyImg2} className='currency-icon currency-position' alt='currency1'></img>
            </div>
            <div className='currency-titels'>{props.currencyTitle1}/{props.currencyTitle2}</div>
        </div>
        <div className='responsive-wrapper'>
            <div className='currency-total'>
                <div className='currency-total-title'>Total Value Loked</div>
                <div className='currency-total-value'>${props.currencyValue}</div>
            </div>
            <div className='currency-total'>
                <div className='currency-total-title'>Total 24h Loked</div>
                <div className='currency-total-value'>${props.currencyValue24}</div>
            </div> 
        </div>
        <Button to='./' name='Deposit'></Button>

    </div>)
}

export default PoolItem;