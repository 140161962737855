import './SelectWallet.css'
import pfantom from '../Assets/pfantpm_ico.svg'
import { useSelector, useDispatch } from 'react-redux';
import { walletActions } from '../Store/wallet-slice';
const SelectWallet = () => {
    const dispatch = useDispatch();
    const walletState = useSelector((state:any) => state.wallet.walletState);
    let style =''
    if(walletState){
        style = 'wallet-wrapper open-wallet'
    }
    else {
        style = 'wallet-wrapper close-wallet'
    }

    const xBtnHandler = () => {
        if(walletState === true) {
            dispatch(walletActions.close());
          }
          else {
            dispatch(walletActions.open());
          }
    

    }

    


    return(<div className={style}>
        <div className='xBtn' onClick={xBtnHandler}></div>
        <div className='wallet-header'>Select Wallet</div>
        <div className='wallet-content'>
            <div className='wallet-item left'>
                <div className='wallet-item-title'>Phantom</div>
                <img src={pfantom} alt='icon' className='wallet-item-img'></img>
            </div>
            <div className='wallet-item'>
                <div className='wallet-item-title'>Phantom</div>
                <img src={pfantom} alt='icon' className='wallet-item-img'></img>
            </div>
            <div className='wallet-item left'>
                <div className='wallet-item-title'>Phantom</div>
                <img src={pfantom} alt='icon' className='wallet-item-img'></img>
            </div>
            <div className='wallet-item'>
                <div className='wallet-item-title'>Phantom</div>
                <img src={pfantom} alt='icon' className='wallet-item-img'></img>
            </div>
            
        </div>
    </div>)
}

export default SelectWallet