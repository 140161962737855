import {Route} from 'react-router-dom';
import Header from './Menu/Header'
import Footer from './Footer/Footer';
import Swap from './Swap/Swap';
import Pools from './Pools/Pools';
import Wallet from './Wallet/Wallet';
import SelectWallet from './Pools/SelectWallet';
import { walletActions } from './Store/wallet-slice';

import { useSelector, useDispatch } from 'react-redux';

import './App.css'



function App() {
  const dispatch = useDispatch();
  const walletState = useSelector((state:any) => state.wallet.walletState)
  let style = '';
  if(walletState){
    style = 'wallet-backdrop-open'
  }
  else{
    style = 'wallet-backdrop-close'
  }

  const walletBackdropHamdler = () => {
    if(walletState){
      dispatch(walletActions.close())
    }
    else{
      dispatch(walletActions.open())
    }

  }
  return (
  <div>
    <Header></Header>
    <main>
      <div className={style} onClick={walletBackdropHamdler}></div>
      <SelectWallet></SelectWallet>
      <Route path='/' exact>
        <Swap></Swap>
      </Route>
      <Route path='/pools'>
        <Pools></Pools>
      </Route>
      <Route path='/wallet'>
        <Wallet></Wallet>
      </Route>
      
    </main>
    <Footer></Footer>

  </div>

  )
}

export default App;
