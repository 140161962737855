import { configureStore } from '@reduxjs/toolkit'

import navSlice from './navigation-slice'
import walletSlice from './wallet-slice';
import slippageSlice from './slippage-slice';



const store = configureStore({
    reducer: { nav: navSlice.reducer, wallet:walletSlice.reducer, slippage: slippageSlice.reducer},
  });

export default store;