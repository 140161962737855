import { createSlice } from '@reduxjs/toolkit'

const slippageSlice = createSlice({
    name: 'slippage',
    initialState: { slippageState: false},
    reducers: {
      open(state) {
        state.slippageState = true;
      },
      close(state) {
        state.slippageState = false;
      },
    },
  });
  
  export const slippageActions = slippageSlice.actions;
  
  export default slippageSlice;
