import { NavLink } from 'react-router-dom';
import { useSelector,  useDispatch } from 'react-redux';
import  './Header.css';
import HamburgerBtn from './HamburgerBtn';
import { navActions } from '../Store/navigation-slice';
import { walletActions } from '../Store/wallet-slice';

const Navigation = () => {
    const dispatch = useDispatch();
    const navState = useSelector(state => state.nav.navState);
    const walletState = useSelector(state => state.wallet.walletState)
    let style =''
    let bkdStyle = ''
    if (navState){
        style = 'open'
        bkdStyle = 'backdrop'

    }
    else {
        style = 'navigation hidden'
        bkdStyle = 'hidden'
    }

    const bkdHandler = () => {
        if(navState === true) {
          dispatch(navActions.close());
        } else {
          dispatch(navActions.open());  
        }
    }
    const bkLinkHandler = () => {
          dispatch(navActions.close());
    }

    const walletBtnHandler = () => {
      
        if(walletState === true) {
          dispatch(walletActions.close());
          console.log(walletState);
        }
        else {
          dispatch(walletActions.open());
          console.log(walletState);
        }
  
        
        
      }

    return (
        <div className='header'>
            <div className='header-container '>
                <div className='navigation-wrapper'>
                    <NavLink to='./' className='logo-link' onClick={bkLinkHandler}>
                        <div className='logo'></div>
                    </NavLink>
                    <div className={style}>
                        <NavLink to='./' onClick={bkLinkHandler}>SWAP</NavLink>
                        <NavLink to='./pools' onClick={bkLinkHandler}>POOLS</NavLink>
                    </div>  
                </div>
                <div className='header-right-side'>
                    <NavLink to='./' className='logo-link mobi' onClick={bkLinkHandler}>
                        <div className='logo mobi'></div>
                    </NavLink>
                    <div  className='wallet-btn' onClick={walletBtnHandler}> Connect Wallet</div>
                    <div className='profile-btn'></div>
                    <HamburgerBtn></HamburgerBtn>
                    <div className={bkdStyle} onClick={bkdHandler}></div>
                </div>
            </div>
        </div>
    )
};

export default Navigation;